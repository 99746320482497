// DON'T FORMAT ON SAVE. TRAILING SPACES ARE BEING USED FOR LINE BREAKS

export const timelineHighlights = [
  {
    name: "Dated range",
    markdown: `Use this section of the timeline to see when and which types of events took place. You can also {% inlineRouterLink articleId="time-travel" %}time travel{% /inlineRouterLink %} and select events.`,
  },
  {
    name: "Bounds",
    markdown: `The oldest date is on the left and the newest date is on the right. Learn how to {% inlineRouterLink articleId="time-travel" sectionName="Adjust the timeline bounds" %}set a new bound{% /inlineRouterLink %}.`,
  },
  {
    name: "Events",
    markdown: `The timeline displays different {% inlineRouterLink articleId="timeline-event-markers" %}event markers{% /inlineRouterLink %} for {% inlineRouterLink articleId="the-role-of-investments" %}investments{% /inlineRouterLink %}, {% inlineRouterLink %}company involvements{% /inlineRouterLink %}, and {% inlineRouterLink %}milestone dates{% /inlineRouterLink %}.`,
  },
  {
    name: "Undated section",
    markdown: `In Tower Hunt, it is often useful to know that something took place even if the date is missing. The right end of the timeline is reserved for these types of events. They are an opportunity for you to either add a missing datapoint or to {% inlineRouterLink %}unlock a datapoint{% /inlineRouterLink %}.`,
  },
];

export const timeline = {
  order: 0,
  name: "Timeline",
  subcategory: "Time travel",
  markdown: `# Timeline

  The timeline displays events and lets you time travel the database. It appears on {% inlineRouterLink articleId="the-role-of-the-map" %}Big Map{% /inlineRouterLink %} (at the city level and closer), {% inlineRouterLink %}property pages{% /inlineRouterLink %}, {% inlineRouterLink %}company pages{% /inlineRouterLink %}, {% inlineRouterLink %}contact pages{% /inlineRouterLink %}, and {% inlineRouterLink %}Deals Hub{% /inlineRouterLink %}.

  You'll see a dated range that may include event markers and a section for undated (or {% inlineRouterLink %}unlockable{% /inlineRouterLink %}) events.

  ![Timeline screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% tabbedContainer tabs=$timelineHighlights /%}`,
};
